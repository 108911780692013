//import { t } from "translations"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { ILinkList } from "data/contentData/interfaces/mongodb/ILinkList"

import Link from "Components/Link"

export default function LinkedLists({
    linkedLists
}: {
    linkedLists: ILinkList[]
}) {
    if (!linkedLists || linkedLists.length === 0) return null

    return (
        <StyledDiv>
            {linkedLists.map(linkedList => (
                <StyledWrapper key={linkedList.name}>
                    <Typography variant="h2">{linkedList.name}</Typography>
                    <StyledListWrapper>
                        {linkedList.links.map(link => (
                            <li key={link.href}>
                                <StyledLink href={link.href}>
                                    {link.text}
                                </StyledLink>
                            </li>
                        ))}
                    </StyledListWrapper>
                </StyledWrapper>
            ))}
        </StyledDiv>
    )
}

const StyledDiv = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(4)
}))

const StyledWrapper = styled("div")(({}) => ({
    display: "flex",
    flexDirection: "column"
}))

const StyledListWrapper = styled("ul")(({ theme }) => ({
    margin: theme.spacing(2, 0)
}))

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.link,
    textDecoration: "underline",
    textUnderlineOffset: "0.1em",
    "&:hover": {
        textDecoration: "none"
    }
}))
