import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import Icon from "Components/Icon/Icon"
import PortableText from "Components/PortableText/PortableText"

export default function BlurbContainer({
    card: containerCard
}: {
    card: IContainerCard
}) {
    return (
        <StyledWrapper>
            {containerCard.cards.map(card => (
                <StyledBlurb key={card.contentGuid ?? card.contentId}>
                    <StyledIcon name={card.icon} />
                    <Typography variant="h2">{card.title}</Typography>
                    <StyledBlurbContent>
                        <PortableText value={card.blockContent} />
                    </StyledBlurbContent>
                    {card.more?.href && card.more.text && (
                        <StyledButton
                            variant="contained"
                            size="large"
                            href={card.more.href}>
                            {card.more.text}
                        </StyledButton>
                    )}
                </StyledBlurb>
            ))}
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gap: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2.8)
    }
}))

const StyledBlurb = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: `${theme.spacing(0.25)} solid`,
    borderColor: theme.palette.secondary.dark,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0)
}))

const StyledIcon = styled(Icon)(({ theme }) => ({
    fontSize: "3rem",
    color: theme.palette.secondary[500],
    "&.MuiSvgIcon-root": {
        width: "100%",
        height: "2em"
    }
}))

const StyledBlurbContent = styled("div")(({ theme }) => ({
    flexGrow: 1,
    marginBottom: theme.spacing(3)
}))

const StyledButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    bottom: 0,
    marginBottom: theme.spacing(-3)
}))
