import { t } from "translations"

import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { getIsArticleOutdated } from "lib/getIsOutdated"

import Icon from "Components/Icon/Icon"

export default function Outdated({ page }: { page: IPublic }) {
    if (getIsArticleOutdated(page))
        return (
            <StyledContent variant="subtitle2">
                <StyledIcon
                    name="errorOutline"
                    fontSize="medium"
                />
                {t.article.outdated}
            </StyledContent>
        )

    return null
}

const StyledContent = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.secondary[100],
    padding: theme.spacing(1),
    lineHeight: theme.spacing(2),
    width: "100%"
}))

const StyledIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    width: theme.spacing(2.5)
}))
