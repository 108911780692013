import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

export default function useDeviceDimensions() {
    const theme = useTheme()

    const mq_xs = useMediaQuery(theme.breakpoints.only("xs"))

    const mq_sm = useMediaQuery(theme.breakpoints.only("sm"))

    const mq_md = useMediaQuery(theme.breakpoints.only("md"))

    if (mq_xs) return "sm"
    if (mq_sm) return "sm"
    if (mq_md) return "md"

    return "lg"
}
