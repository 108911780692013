import { t } from "translations"

import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import useGetMoreFromPath from "data/hooks/useGetMoreFromPath"
import { getParentTopic } from "lib/getParentTopic"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

import WidgetCard from "./WidgetCard"
import WidgetHeader from "./WidgetHeader"

const deviceDimensions: IDeviceDimensions = {
    sm: {
        height: 1834
    },
    md: {
        height: 2190
    },
    lg: {
        height: 1095
    }
}

export default function MoreFrom({ page }: { page: IPublic }) {
    const parentTopic = getParentTopic(page)
    const { userCategory } = useUserCategory()
    const isProfessionalUser = userCategory === "professional"

    const { data, error, isLoading } = useGetMoreFromPath(
        parentTopic?.url,
        isProfessionalUser,
        page.url
    )

    const breakpoint = useDeviceDimensions()

    const skeletonValues = deviceDimensions[breakpoint]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
            />
        )

    if (!data || !data.length) return null

    return (
        <StyledWrapper>
            <WidgetHeader
                headerPrefix={t.moreFrom}
                header={`${parentTopic?.name}`}
            />
            <StyledGrid
                container
                spacing={2}>
                {data.map((moreFrom, index) => (
                    <PageTeaser
                        id={`more-from-card-${index + 1}`}
                        key={moreFrom.url}
                        pageTeaser={moreFrom}
                    />
                ))}
            </StyledGrid>
        </StyledWrapper>
    )
}

function PageTeaser({
    pageTeaser,
    id
}: {
    pageTeaser: IArticleCard
    id: string
}) {
    return (
        <Grid
            item
            xs={12}
            md={6}>
            <WidgetCard
                pageTeaser={pageTeaser}
                id={id}
            />
        </Grid>
    )
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2)
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    border: `${theme.spacing(0.1)} solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.paper
}))
