import { t } from "translations"

import Typography from "@mui/material/Typography"
import { grey } from "@mui/material/colors"
import { styled } from "@mui/material/styles"

export default function ContentMarketingAdDisclaimer() {
    return (
        <StyledWrapper>
            <Typography variant="body2">
                {t.contentMarketing.adDisclaimer}
            </Typography>
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(4),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: grey[200]
}))
