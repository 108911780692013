import { get, HttpError } from "@nhi/utils"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import { IContainerCard } from "../interfaces/mongodb/IContainerCard"
import { ISitemap } from "../interfaces/mongodb/ISitemap"
import { IStart } from "../interfaces/mongodb/IStart"

/**
 * Endpoint for fething all data, except block content
 * @param path path to the page... what did you expect ?
 * @param includeProDynamicContent include pro dynamic content
 * @returns page without block content
 */
export async function getPage(path: string, includeProDynamicContent: boolean) {
    const endpoint =
        `${process.env.NEXT_PUBLIC_CONTENT_API}/Content/Page?path=` +
        encodeURIComponent(path) +
        `&includeProDynamicContent=${includeProDynamicContent}`

    const { data } = await get<IPage | null>(endpoint, undefined).catch(
        (httpError: HttpError) => {
            if (httpError.response?.status == 404) return { data: null }
            else throw httpError
        }
    )
    return data
}

/**
 * Get footer content
 * @returns Array of footer items
 */
export async function getFooter() {
    const frontPage = (await getPage("/", false)) as IStart
    return frontPage?.footer ? (frontPage.footer as IContainerCard[]) : []
}

/**
 * Get site map
 * @returns Sitemap from CMS
 */
export async function getSitemap() {
    const endpoint = `${process.env.NEXT_PUBLIC_CONTENT_API}/Content/Sitemap`

    const { data } = await get<ISitemap[] | null>(endpoint, undefined)
    return data
}
