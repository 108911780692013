import { t } from "translations"

import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import useGetMoreFromPath from "data/hooks/useGetMoreFromPath"
import { getParentTopic } from "lib/getParentTopic"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"

import WidgetHeader from "Components/Article/Widgets/WidgetHeader"
import useUserCategory from "Components/UserCategoryModal/useUserCategory"

import WidgetCard from "../../Article/Widgets/WidgetCard"

const deviceDimensions: IDeviceDimensions = {
    sm: {
        height: 1200
    },
    md: {
        height: 1125
    },
    lg: {
        height: 1500
    }
}

export default function MoreFromAside({ page }: { page: IPublic }) {
    const { userCategory } = useUserCategory()
    const isProfessionalUser = userCategory === "professional"
    const parentTopic = page.topic

    const atPos = -3
    const getMoreFromUrl =
        page.template?.className === "content-marketing-advertiser"
            ? page.url
            : getParentTopic(page, atPos)?.url

    const { data, error, isLoading } = useGetMoreFromPath(
        getMoreFromUrl,
        isProfessionalUser,
        page.url
    )

    const breakpoint = useDeviceDimensions()

    const skeletonValues = deviceDimensions[breakpoint]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
            />
        )
    if (!data || data.length === 0) return null

    const header = parentTopic ?? data[0].topic

    return (
        <StyledWrapper>
            <WidgetHeader
                headerPrefix={t.moreFrom}
                header={`"${header}"`}
                isContentMarketing={true}
            />
            <StyledGrid
                container
                spacing={2}
                gap={2}>
                {data.map((moreFrom, index) =>
                    moreFrom.title !== page.title ? (
                        <RelatedArticle
                            key={moreFrom.url}
                            pageTeaser={moreFrom}
                            showImage={index === 0}
                        />
                    ) : null
                )}
            </StyledGrid>
        </StyledWrapper>
    )
}

function RelatedArticle({
    pageTeaser,
    showImage
}: {
    pageTeaser: IArticleCard
    showImage: boolean
}) {
    return (
        <Grid
            item
            xs={12}>
            <WidgetCard
                pageTeaser={pageTeaser}
                showImage={showImage}
            />
        </Grid>
    )
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2)
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    border: `${theme.spacing(0.1)} solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.advertiser[50]
}))
