import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { ICMPublicVideo } from "data/contentData/interfaces/mongodb/ICMPublicVideo"

import ContentImage from "Components/Image/ContentImage"

export default function ContentMarketingHeader({
    page
}: {
    page: ICMPublicVideo
}) {
    const showIngressImage = !page.types.includes("PublicVideo")
    return (
        <StyledArticleHeader>
            <Typography variant="h1">{page.title}</Typography>
            <StyledIngress variant="body2">{page.ingress}</StyledIngress>
            {page.image && showIngressImage && (
                <>
                    <ContentImage image={page.image} />
                    {page.image.caption && (
                        <StyledFigCaption>
                            <Typography variant="caption">
                                {page.image.caption}
                            </Typography>
                        </StyledFigCaption>
                    )}
                </>
            )}
        </StyledArticleHeader>
    )
}

const StyledArticleHeader = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))

const StyledFigCaption = styled("figcaption")(({ theme }) => ({
    marginTop: theme.spacing(-2),
    padding: theme.spacing(1, 0)
}))

const StyledIngress = styled(Typography)(() => ({
    fontFamily: "FigtreeLight",
    fontSize: "1.375rem"
}))
