import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import ArticleTopic from "Components/ArticleTopic"
import CardTitle from "Components/CardTitle"
import ContentImage from "Components/Image/ContentImage"
import Link from "Components/Link"

export default function SplashContainer({ card }: { card: IContainerCard }) {
    return (
        <div>
            <StyledTopicTitle
                variant="h4"
                component="h1">
                {card.title}
            </StyledTopicTitle>
            <StyledWrapper columns={card.cards.flatMap(x => x.columns)}>
                {card.cards.map(cardOfCards => (
                    <StyledSplash
                        color="inherit"
                        key={cardOfCards.url}
                        href={cardOfCards.url}>
                        <ContentImage image={cardOfCards.image} />
                        <StyledCardWrapper>
                            <ArticleTopic topic={cardOfCards.topic} />
                            <CardTitle title={cardOfCards.title} />
                            <StyledIngress variant="body1">
                                {cardOfCards.ingress}
                            </StyledIngress>
                        </StyledCardWrapper>
                    </StyledSplash>
                ))}
            </StyledWrapper>
        </div>
    )
}

const StyledTopicTitle = styled(Typography)<{ component: string }>(
    ({ theme }) => ({
        marginBottom: theme.spacing(1)
    })
)

const StyledCardWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2, 2, 2),
    textAlign: "center"
}))

const StyledWrapper = styled("div")<{ columns: number[] }>(
    ({ theme, columns }) => ({
        display: "grid",
        flexDirection: "column",
        gridTemplateColumns: "1fr",
        gap: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: columns.map(x => `${x}fr`).join(" ")
        }
    })
)

const StyledSplash = styled(Link)(({ theme }) => ({
    display: "block",
    whiteSpace: "unset",
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledIngress = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(0.5)
}))
