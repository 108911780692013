import { t } from "translations"

import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import useQueryData from "data/hooks/useQueryData"
import { getRelatedArticles } from "data/searchManager/api/search"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"

import useUserCategory from "Components/UserCategoryModal/useUserCategory"

import WidgetCard from "./WidgetCard"
import WidgetHeader from "./WidgetHeader"

const deviceDimensions: IDeviceDimensions = {
    sm: {
        height: 1200
    },
    md: {
        height: 1125
    },
    lg: {
        height: 1500
    }
}

export default function Related({ page }: { page: IPublic }) {
    const { userCategory } = useUserCategory()
    const isProfessionalUser = userCategory === "professional"

    const {
        data: relatedArticles,
        error,
        isLoading
    } = useQueryData<IArticleCard[]>(
        `related-content-marketing-${page.contentId}`,
        () =>
            getRelatedArticles(
                page.contentId,
                "/annonsorinnhold",
                page.url,
                2,
                isProfessionalUser,
                true
            )
    )

    const breakpoint = useDeviceDimensions()

    const skeletonValues = deviceDimensions[breakpoint]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
            />
        )

    if (!relatedArticles || !relatedArticles.length) return null

    return (
        <StyledWrapper>
            <WidgetHeader
                headerPrefix={t.relatedContentMarketing}
                isContentMarketing={true}
            />
            <StyledGrid
                container
                spacing={2}>
                {relatedArticles.map((relatedArticle, index) => (
                    <RelatedArticle
                        id={`content-card-${index + 1}`}
                        key={relatedArticle.url}
                        pageTeaser={relatedArticle}
                        showImage={index === 0}
                    />
                ))}
            </StyledGrid>
        </StyledWrapper>
    )
}

function RelatedArticle({
    pageTeaser,
    showImage,
    id
}: {
    pageTeaser: IArticleCard
    showImage: boolean
    id: string
}) {
    return (
        <Grid
            item
            xs={12}>
            <WidgetCard
                id={id}
                pageTeaser={pageTeaser}
                showImage={showImage}
            />
        </Grid>
    )
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2)
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    border: `${theme.spacing(0.1)} solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.advertiser[50]
}))
