import { get } from "@nhi/utils"
import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"

/**
 * Endpoint for searching articles based on Topic
 * @param path Related path
 * @returns list of matching page teasers
 */
export async function getMoreFromPath(
    path: string | undefined,
    skip = 0,
    take = 4,
    isProfessionalUser = false
) {
    if (!path) return []

    const endpoint =
        `${process.env.NEXT_PUBLIC_CONTENT_API}/Search/MoreFromPath` +
        `?path=${encodeURIComponent(path)}` +
        "&pageType=Public" +
        `&skip=${skip}` +
        `&take=${take}` +
        `&includeProfessionalContent=${isProfessionalUser}`
    const { data } = await get<IArticleCard[]>(endpoint)
    return data ?? []
}
