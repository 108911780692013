import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export default function ArticleTopic({
    topic,
    id
}: {
    topic: string | undefined
    id?: string
}) {
    if (!topic) return null

    return (
        <StyledTopic
            id={id}
            component="p"
            variant="overline">
            {topic}
        </StyledTopic>
    )
}

const StyledTopic = styled(Typography)<{ component: string }>(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
}))
