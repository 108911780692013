import { Dispatch, SetStateAction } from "react"
import { t } from "translations"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { getFragment } from "data/contentData/api/fragment"
import useQueryData from "data/hooks/useQueryData"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"

import Icon from "Components/Icon/Icon"
import PortableText from "Components/PortableText/PortableText"

const deviceDimensions: IDeviceDimensions = {
    sm: {
        height: 730,
        width: 340
    },
    md: {
        height: 620,
        width: 575
    },
    lg: {
        height: 480,
        width: 1080
    }
}

export default function QualityCheckModal({
    open,
    setOpen
}: {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}) {
    const { data, isLoading, error } = useQueryData<any>(
        "qualitycheck",
        getFragment
    )

    const breakpoint = useDeviceDimensions()

    const skeletonValues = deviceDimensions[breakpoint]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
                width={skeletonValues.width}
            />
        )

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal">
            <StyledBox>
                <StyledTitleWrapper>
                    <Typography variant="h2">{t.qualityCheck.title}</Typography>
                    <IconButton
                        color="primary"
                        aria-label={t.qualityCheck.close}
                        onClick={() => setOpen(false)}>
                        <Icon name="close" />
                    </IconButton>
                </StyledTitleWrapper>

                {data.blockContent && (
                    <Typography
                        variant="body2"
                        component="section">
                        <PortableText value={data.blockContent} />
                    </Typography>
                )}

                <StyledModalButton
                    variant="contained"
                    onClick={() => setOpen(false)}>
                    {t.qualityCheck.close}
                </StyledModalButton>
            </StyledBox>
        </Modal>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    height: "fit-content",
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    maxWidth: "800px",
    width: "90vw",
    overflowY: "auto",
    maxHeight: "90vh",
    [theme.breakpoints.up("sm")]: {
        width: "70%",
        overflowY: "hidden"
    },
    [theme.breakpoints.up("md")]: {
        width: "50%",
        overflowY: "hidden"
    }
}))

const StyledTitleWrapper = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
})

const StyledModalButton = styled(Button)(({ theme }) => ({
    marginLeft: "auto",
    marginTop: theme.spacing(2)
}))
