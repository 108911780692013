import { getMoreFromPath } from "data/contentData/api/search"
import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"

import useQueryData from "./useQueryData"

const MOREFROM_SKIP = 0
const MOREFROM_TAKE = 5

/**
 * Hook for fetching more from articles
 * @param path article parent path to fetch more from
 * @param isProfessionalUser whether the user is professional or layment
 * @param currentUrl current url of the article
 * @returns list of filtered more from articles
 */
export default function useGetMoreFromPath(
    path: string | undefined,
    isProfessionalUser: boolean,
    currentUrl: string
) {
    const { data, error, isLoading } = useQueryData<IArticleCard[]>(
        `morefrom-${path}-${isProfessionalUser}`,
        () =>
            getMoreFromPath(
                path,
                MOREFROM_SKIP,
                MOREFROM_TAKE,
                isProfessionalUser
            )
    )
    const dataFiltered = getMoreFromPathFilterCurrentArticle(
        data,
        currentUrl,
        MOREFROM_TAKE - 1
    )

    return {
        data: dataFiltered,
        error,
        isLoading
    }
}

/**
 * Filters out the current article from the more from articles
 * @param data data to filter
 * @param currentArticleUrl current article to filter out
 * @param take max rows to return
 * @returns filtered rows
 */
function getMoreFromPathFilterCurrentArticle(
    data: IArticleCard[] | undefined,
    currentArticleUrl: string,
    take: number
) {
    if (!data) return undefined
    return data.filter(x => x.url !== currentArticleUrl).slice(0, take)
}
