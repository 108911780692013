import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IArticlesCard } from "data/contentData/interfaces/mongodb/IArticlesCard"

import MoreButton from "Components/MoreButton"

import TeaserCard from "./TopicContainerCard/TeaserCard"

export default function TopicArticlesCard({ card }: { card: IArticlesCard }) {
    if (!card.articles) return null
    const numberOfArticles = card.articles.length

    return (
        <div>
            {card.title && (
                <StyledTopicTitle
                    variant="h4"
                    component="h1">
                    {card.title}
                </StyledTopicTitle>
            )}
            <StyledWrapper>
                {card.articles.map((article, index) => (
                    <TeaserCard
                        teaser={article}
                        index={index}
                        numberOfArticles={numberOfArticles}
                        key={article.url}
                    />
                ))}
            </StyledWrapper>
            <MoreButton card={card} />
        </div>
    )
}

const StyledTopicTitle = styled(Typography)<{ component: string }>(
    ({ theme }) => ({
        marginBottom: theme.spacing(1)
    })
)

const StyledWrapper = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridAutoRows: "min-content",
    gap: theme.spacing(2),
    width: "100%"
}))
