import React from "react"

import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import ArticleHeader from "Components/Article/ArticleHeader"
import Head from "Components/Head"

import Sections from "./Sections"
import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"

export default function SystemTemplate({ page, paging }: { page: IPublic, paging: IPagingContext | null }) {
    return (
        <>
            <Head page={page} paging={paging} />
            <StyledWrapper>
                <StyledArticle>
                    <ArticleHeader page={page} />
                    <div className="article">
                        <Sections sections={page.sections} />
                    </div>
                </StyledArticle>
            </StyledWrapper>
        </>
    )
}

const StyledWrapper = styled("main")(({ theme }) => ({
    maxWidth: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4)
    }
}))

const StyledArticle = styled("article")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))
