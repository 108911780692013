import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

interface IProps {
    headerPrefix: string
    header?: string
    isContentMarketing?: boolean
}

export default function WidgetHeader({
    headerPrefix,
    header,
    isContentMarketing = false
}: IProps) {
    return (
        <StyledWrapperParent
            variant="h5"
            component="h3"
            iscontentmarketing={isContentMarketing.toString()}>
            {headerPrefix}
            {header && (
                <StyledWrapperParentName>{header}</StyledWrapperParentName>
            )}
        </StyledWrapperParent>
    )
}

const StyledWrapperParent = styled(Typography)<{
    component: string
    iscontentmarketing: string
}>(({ theme, iscontentmarketing }) => ({
    color: iscontentmarketing === "true" ? "#fff" : theme.palette.text.primary,
    backgroundColor:
        iscontentmarketing === "true"
            ? theme.palette.advertiser.main
            : theme.palette.secondary.opacityBackground,

    margin: 0,
    padding: theme.spacing(2, 2)
}))

const StyledWrapperParentName = styled("span")(({ theme }) => ({
    fontWeight: 300,
    marginLeft: theme.spacing(1)
}))
