import Chip from "@mui/material/Chip"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { ITopic } from "data/contentData/interfaces/mongodb/ITopic"

import Link from "Components/Link"

export default function SubHeadersCard({ page }: { page: ITopic }) {
    const showSubHeaders =
        page.children && page.children.length > 0 && page.parents?.length === 1

    if (page.types.includes("Topic") && page.parents.length >= 1)
        return (
            <StyledWrapper>
                <Typography
                    variant="h1"
                    textAlign="center">
                    {page.name}
                </Typography>
                {showSubHeaders && (
                    <StyledChipWrapper>
                        {page.children.map(child => {
                            return (
                                <Link
                                    href={child.url}
                                    key={child.name}>
                                    <StyledChip
                                        clickable
                                        key={child.name}
                                        label={
                                            <Typography variant="body2">
                                                {child.name}
                                            </Typography>
                                        }
                                    />
                                </Link>
                            )
                        })}
                    </StyledChipWrapper>
                )}
            </StyledWrapper>
        )
    else return null
}

const StyledWrapper = styled("div")(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(-1.5),
    [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(-4),
        marginBottom: theme.spacing(1)
    }
}))

const StyledChipWrapper = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
        gap: theme.spacing(2)
    }
}))

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.spacing(0.4),
    backgroundColor: theme.palette.secondary.opacityBackground
}))
