import { t } from "translations"

import Grid from "@mui/material/Grid"
import Skeleton from "@mui/material/Skeleton"
import { styled } from "@mui/material/styles"

import { getMostRead } from "data/contentData/api/analytics"
import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import useQueryData from "data/hooks/useQueryData"
import { IDeviceDimensions } from "lib/hooks/deviceDimensions/IDeviceDimensions"
import useDeviceDimensions from "lib/hooks/deviceDimensions/useDeviceDimensions"

import WidgetCard from "./WidgetCard"
import WidgetHeader from "./WidgetHeader"

const deviceDimensions: IDeviceDimensions = {
    sm: {
        height: 1345
    },
    md: {
        height: 1795
    },
    lg: {
        height: 1445
    }
}

export default function MostRead() {
    const {
        data: teasers,
        error,
        isLoading
    } = useQueryData<IArticleCard[]>("most-read", () => getMostRead())

    const breakpoint = useDeviceDimensions()

    const skeletonValues = deviceDimensions[breakpoint]

    if (error) return null

    if (isLoading)
        return (
            <Skeleton
                variant="rectangular"
                height={skeletonValues.height}
            />
        )

    if (!teasers) return null

    return (
        <StyledWrapper>
            <WidgetHeader headerPrefix={t.mostRead} />
            <StyledGrid
                container
                spacing={2}>
                {teasers.map((teaser, index) => (
                    <MostReadEntry
                        id={`most-read-card-${index + 1}`}
                        key={teaser.url}
                        pageTeaser={teaser}
                        showImage={index === 0}
                    />
                ))}
            </StyledGrid>
        </StyledWrapper>
    )
}

function MostReadEntry({
    pageTeaser,
    showImage,
    id
}: {
    pageTeaser: IArticleCard
    showImage: boolean
    id: string
}) {
    return (
        <Grid
            item
            xs={12}>
            <WidgetCard
                pageTeaser={pageTeaser}
                showImage={showImage}
                id={id}
            />
        </Grid>
    )
}

const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2)
}))

const StyledWrapper = styled("div")(({ theme }) => ({
    border: `${theme.spacing(0.1)} solid ${theme.palette.primary.main}`
}))
