import { differenceInDays } from "date-fns/differenceInDays"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

/**
 * Get difference in days between current date and param and check if it is more than 2 years
 * @param date which date to compare to current date
 * @returns true if article is outdated
 */
export function getIsArticleOutdated(page: IPublic) {
    if (
        !page.template ||
        !(["news-article", "quiz"].some(x => page.template?.className === x)) ||
        differenceInDays(Date.now(), new Date(page.revised!)) < 730 === true ||
        differenceInDays(Date.now(), new Date(page.published!)) < 730 ===
        true ||
        differenceInDays(Date.now(), new Date(page.updated!)) < 730 === true
    )
        return false

    return true
}
