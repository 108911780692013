import { GetStaticPropsContext } from "next"
import dynamic from "next/dynamic"
import { ParsedUrlQuery } from "querystring"

import { isNonPage, isPublished, toPath } from "@nhi/utils"
import { getFooter, getPage } from "data/contentData/api/content"
import { getMegamenu } from "data/contentData/api/megamenu"
import { getRedirectSingle } from "data/contentData/api/redirect"
import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IArticle } from "data/contentData/interfaces/mongodb/IArticle"
import { ICMPublicVideo } from "data/contentData/interfaces/mongodb/ICMPublicVideo"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"
import { IList } from "data/contentData/interfaces/mongodb/IList"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"
import { IPublicVideo } from "data/contentData/interfaces/mongodb/IPublicVideo"
import { ITopic } from "data/contentData/interfaces/mongodb/ITopic"
import { CONTENT_TYPE, getContentType } from "lib/getContentType"
import useGtmCustomVariables from "lib/hooks/useGtmCustomVariables"
import { rewrites } from "lib/routes"

import { AdContextProvider } from "Components/Ad/AdContext"
import Breadcrumbs from "Components/Breadcrumbs"
import Layout from "Components/Layout/Layout"
import { GalleryProvider } from "Components/Layout/PageComponent/GalleryProvider"
import {
    IPagingContext,
    PageDataProvider
} from "Components/Layout/PageComponent/PageDataProvider"
import { ReferencesProvider } from "Components/Layout/PageComponent/ReferencesProvider"
import useRefreshScrollRestoration from "Components/Layout/PageComponent/useRefreshScrollRestoration"
import NewsletterPopover from "Components/Newsletter/NewsletterPopover"
import ContentMarketingTemplate from "Components/Templates/ContentMarketingTemplate/ContentMarketingTemplate"
import DefaultTemplate from "Components/Templates/DefaultTemplate"
import ListTemplate from "Components/Templates/ListTemplate/ListTemplate"
import SystemTemplate from "Components/Templates/SystemTemplate"
import TopicTemplate from "Components/Templates/TopicTemplate/TopicTemplate"

import {
    checkForPaged,
    getPagingDetails,
    pageShouldOverride,
    shouldCheckForRedirect
} from "./Redirect/getRedirects"

const UserCategoryModal = dynamic(
    () => import("Components/UserCategoryModal/UserCategoryModal"),
    {
        ssr: false
    }
)

const AdSticky = dynamic(() => import("Components/Ad/AdSticky"), {
    ssr: false
})

const Debug = dynamic(() => import("Components/Debug/Debug"), {
    ssr: false
})

export default function PageComponent({
    page,
    footer,
    megamenu,
    paging
}: {
    page: IPage
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
    paging: IPagingContext | null
}) {
    useRefreshScrollRestoration()
    useGtmCustomVariables(page)

    if (!page) return null

    const isProfessionalContent =
        (page as IArticle).professionalContent ?? false

    const PageComponentToRender = getPageComponentToRender(page, paging)

    return (
        <AdContextProvider page={page}>
            <Layout
                footer={footer}
                megamenu={megamenu}>
                <Breadcrumbs page={page} />
                <UserCategoryModal
                    isProfessionalContent={isProfessionalContent}
                />

                <AdSticky
                    alignment="left"
                    type="venstre_skyskraper"
                />
                <AdSticky
                    alignment="right"
                    type="hoyre_skyskraper"
                />
                <GalleryProvider page={page}>
                    <PageDataProvider paging={paging}>
                        <ReferencesProvider page={page}>
                            {PageComponentToRender}
                        </ReferencesProvider>
                    </PageDataProvider>
                </GalleryProvider>
                <Debug page={page} />
            </Layout>
        </AdContextProvider>
    )
}

function getPageComponentToRender(page: IPage, paging: IPagingContext | null) {
    let PageComponentToRender: JSX.Element

    const contentType = getContentType(page.types)
    switch (contentType) {
        case CONTENT_TYPE.START:
        case CONTENT_TYPE.TOPIC:
            PageComponentToRender = (
                <TopicTemplate
                    page={page as ITopic}
                    paging={paging}
                />
            )
            break
        case CONTENT_TYPE.LIST:
            PageComponentToRender = (
                <ListTemplate
                    page={page as IList}
                    paging={paging}
                />
            )
            break
        case CONTENT_TYPE.ARTICLE:
            PageComponentToRender = (
                <>
                    <DefaultTemplate
                        page={page as IPublicVideo}
                        paging={paging}
                    />
                    {page.tags && <NewsletterPopover tags={page.tags} />}
                </>
            )
            break
        case CONTENT_TYPE.CONTENTMARKETING_ARTICLE:
            PageComponentToRender = (
                <ContentMarketingTemplate
                    page={page as ICMPublicVideo}
                    paging={paging}
                />
            )
            break
        case CONTENT_TYPE.SYSTEM:
            PageComponentToRender = (
                <SystemTemplate
                    page={page as IPublic}
                    paging={paging}
                />
            )
            break
        default:
            throw new Error(`Unknown content type: ${page.types.join("-")} `)
    }

    return PageComponentToRender
}

async function getStaticPropsBase(
    params: ParsedUrlQuery | undefined,
    includeProDynamicContent: boolean
) {
    const path = toPath(params?.page)

    const revalidate = Number(process.env.REVALIDATE_STATIC_PROPS)

    if (!path || isNonPage(path)) return { props: {}, revalidate }

    let page = await getPage(path, includeProDynamicContent)
    let paging: IPagingContext | null = null

    if (!page) {
        const redirect = await getRedirectSingle(path)
        if (redirect)
            return {
                redirect,
                revalidate
            }

        const shouldRedirect = shouldCheckForRedirect(path)
        if (shouldRedirect) {
            const recheckPage = await getPage(
                shouldRedirect.path,
                includeProDynamicContent
            )
            if (recheckPage)
                return {
                    redirect: {
                        destination: recheckPage.url,
                        permanent: shouldRedirect.permanent
                    },
                    revalidate
                }
        }

        const pagingResult = await checkForPaged(path, includeProDynamicContent)

        if (!pagingResult)
            return {
                notFound: true,
                revalidate
            }
        else {
            page = pagingResult.page
            paging = pagingResult.paging
        }
    }

    if (!isPublished(page.published, page.unpublished)) {
        const destination =
            getContentType(page.types) === CONTENT_TYPE.CONTENTMARKETING_ARTICLE
                ? rewrites["/cm-article-expired"]
                : rewrites["/article-expired"]
        return {
            redirect: { destination },
            revalidate
        }
    }

    const shouldOverride = pageShouldOverride(page)
    if (shouldOverride)
        return {
            redirect: {
                destination: shouldOverride.url,
                permanent: shouldOverride.permanent
            },
            revalidate
        }

    if (page.shortcut) {
        const destination = page.shortcut.replace(/(.+)\/$/, "$1")
        return {
            redirect: {
                destination,
                permanent: true
            },
            revalidate
        }
    }

    const footer = await getFooter()
    const megamenu = await getMegamenu()

    if (!paging) paging = getPagingDetails(path, page)
    return {
        props: { page, footer, megamenu, paging },
        revalidate
    }
}

export async function getStaticProps({ params }: GetStaticPropsContext) {
    return getStaticPropsBase(params, false)
}

export async function getStaticPropsPro({ params }: GetStaticPropsContext) {
    return getStaticPropsBase(params, true)
}
