import { useState } from "react"
import { t } from "translations"

import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { toLocalDate } from "@nhi/utils"
import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import QualityCheckModal from "./QualityCheckModal"
import Outdated from "./Widgets/Outdated"

/**
 * takes the byline string and splits it based on a collon symbol
 * @param byline a string passed from the page object
 * @returns a component containing the correctly formatted byline.
 */

function bylineSplitter(byline: string) {
    if (byline && byline.includes(":")) {
        const splitText = byline.split(":")
        return (
            <div style={{ lineHeight: 1 }}>
                <Typography
                    variant="subtitle2"
                    component="p"
                    display="inline"
                    fontWeight="bold">
                    {`${splitText[0]}: `}
                </Typography>
                <Typography
                    variant="subtitle2"
                    component="p"
                    display="inline">
                    {`${splitText[1]}`}
                </Typography>
            </div>
        )
    } else if (byline && !byline.includes(" : "))
        return (
            <Typography
                variant="subtitle2"
                component="p"
                display="inline">
                {`${byline}`}
            </Typography>
        )
    else return null
}

export default function ArticleMeta({ page }: { page: IPublic }) {
    const [open, setOpen] = useState<boolean>(false)

    return (
        <StyledContainer>
            <StyledDiv>
                {bylineSplitter(page.byline)}
                <div>
                    {page.latest && (
                        <>
                            <Typography
                                variant="subtitle2"
                                component="p"
                                display="inline"
                                fontWeight="bold">
                                {t.article.lastUpdated}{" "}
                            </Typography>
                            <Typography
                                display="inline"
                                variant="subtitle2"
                                component="p">
                                {toLocalDate(
                                    page.latest,
                                    process.env.NEXT_PUBLIC_LOCALE
                                )}
                            </Typography>
                        </>
                    )}
                    <StyledLink
                        component="button"
                        variant="subtitle2"
                        aria-label={t.qualityCheck.title}
                        onClick={() => setOpen(true)}>
                        {t.qualityCheck.title}
                    </StyledLink>
                </div>
            </StyledDiv>
            <Outdated page={page} />

            <QualityCheckModal
                open={open}
                setOpen={setOpen}
            />
        </StyledContainer>
    )
}

const StyledContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1)
}))

const StyledDiv = styled("div")({
    display: "flex",
    flexDirection: "column"
})

const StyledLink = styled(Link)<{ component: string }>(({ theme }) => ({
    float: "right",
    color: theme.palette.primary.link,
    textDecorationColor: theme.palette.primary.link,
    "&:hover": {
        textDecoration: "none"
    }
}))
