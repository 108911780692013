import { post } from "@nhi/utils"

import { NewsletterPopoverType } from "../interfaces/newsletterCookie"

export async function setNewsletterCookie(
    newsletterId: NewsletterPopoverType,
    expires: number
) {
    const body = JSON.stringify({ value: newsletterId, expires: expires })
    await post("/api/newsletterCookie", body)
}
