import { styled } from "@mui/material/styles"

import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import Icon from "Components/Icon/Icon"

import Link from "./Link"

export default function PageBreadcrumbs({ page }: { page: IPage }) {
    if (!page.parents) return null

    const parents = page.parents
        .map(c => ({
            name: c.name,
            url: c.url
        }))
        .reverse()

    const crumbs = [...parents, { name: page.name, url: page.url }]

    return (
        <StyledCrumbs>
            <StyledCrumbsList aria-label="Breadcrumb">
                {crumbs.map((crumb, index) =>
                    crumb.url === page.url ? (
                        <StyledCrumb
                            key={crumb.url}
                            aria-label={crumb.name}>
                            {crumb.name}
                        </StyledCrumb>
                    ) : (
                        <StyledCrumb key={crumb.url}>
                            <Link
                                id={
                                    index === 0
                                        ? "breadcrumb-path-home"
                                        : `breadcrumb-path-${index}`
                                }
                                aria-label={crumb.name}
                                href={crumb.url}
                                color="inherit">
                                {crumb.name === "NHI.no" &&
                                crumb.url === "/" ? (
                                    <StyledHomeIcon
                                        name="home"
                                        fontSize="small"
                                        color="inherit"
                                    />
                                ) : (
                                    crumb.name
                                )}
                            </Link>
                        </StyledCrumb>
                    )
                )}
            </StyledCrumbsList>
        </StyledCrumbs>
    )
}

const StyledCrumbs = styled("div")(({ theme }) => ({
    fontSize: "1rem",
    position: "relative",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
        fontSize: "0.875rem"
    }
}))

const StyledCrumbsList = styled("ul")(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
    width: "100%",
    whiteSpace: "nowrap",
    overflowX: "auto",
    "&::WebkitOverflowScrolling": "touch",
    "&::-webkit-scrollbar": {
        height: "2px"
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up("sm")]: {
        padding: 0,
        marginTop: theme.spacing(1)
    }
}))

const StyledCrumb = styled("li")(({ theme }) => ({
    padding: theme.spacing(1, 0),
    display: "inline-block",
    "&:after": {
        content: "'>'",
        padding: "0 0.5em 0 0.5em"
    },
    "&:last-child": {
        color: theme.palette.grey[700]
    },
    "&:last-child:after": {
        content: "''"
    }
}))

const StyledHomeIcon = styled(Icon)(({ theme }) => ({
    top: theme.spacing(0.5),
    position: "relative"
}))
