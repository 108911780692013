import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

export default function CardTitle({ title }: { title: string | undefined }) {
    if (!title) return null

    return (
        <StyledTeaserHeader
            variant="h2"
            color="inherit">
            {title}
        </StyledTeaserHeader>
    )
}

const StyledTeaserHeader = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    "&:hover": {
        textDecoration: "underline"
    }
}))
