import { styled } from "@mui/material/styles"

import { IArticlesCard } from "data/contentData/interfaces/mongodb/IArticlesCard"

import Icon from "Components/Icon/Icon"
import Link from "Components/Link"

export default function MoreButton({ card }: { card: IArticlesCard }) {
    const href = card.more ? card.more.href : undefined
    const buttonText = card.more ? card.more.text : undefined

    if (!href || !buttonText) return null

    return (
        <StyledLinkWrapper>
            <StyledLink
                href={href}
                variant="h5"
                underline="always">
                {buttonText}
                <StyledArrowForwardIcon name="arrowForward" />
            </StyledLink>
        </StyledLinkWrapper>
    )
}

const StyledArrowForwardIcon = styled(Icon)(({ theme }) => ({
    top: theme.spacing(1),
    position: "relative"
}))

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: "underline",
    padding: `${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(
        1
    )} ${theme.spacing(2)}`,
    "&:hover": {
        textDecoration: `underline ${theme.palette.secondary.main}`
    }
}))

const StyledLinkWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    marginTop: theme.spacing(2)
}))
