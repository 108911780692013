import { useEffect } from "react"

import throttle from "lib/throttle"

/**
 * Hook for maintaining scroll position on page reload
 */
function useRefreshScrollRestoration() {
    useEffect(() => {
        const pageAccessedByReload = window.performance
            .getEntriesByType("navigation")
            .map(nav => nav.entryType)
            .includes("reload")

        if (pageAccessedByReload) {
            const sessionScrollPosition =
                sessionStorage.getItem("scrollPosition")
            if (sessionScrollPosition) {
                const scrollPosition = Number.parseInt(
                    sessionScrollPosition,
                    10
                )
                if (scrollPosition) window.scrollTo(0, scrollPosition)
            }
        }

        const [throttledFn] = throttle(
            () =>
                sessionStorage.setItem(
                    "scrollPosition",
                    window.scrollY.toString()
                ),
            500
        )
        const handleScroll = throttledFn

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
}

export default useRefreshScrollRestoration
