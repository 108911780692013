import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"

import ArticleTopic from "Components/ArticleTopic"
import ContentImage from "Components/Image/ContentImage"
import Link from "Components/Link"

export default function WidgetCard({
    pageTeaser,
    id,
    showImage = true
}: {
    pageTeaser: IArticleCard
    showImage?: boolean
    id?: string | undefined
}) {
    const image = pageTeaser.image

    return (
        <StyledBox>
            <StyledLink
                id={id}
                href={pageTeaser.url}
                color="inherit">
                {showImage && (
                    <ContentImage
                        image={image}
                        id={id}
                    />
                )}
                <ArticleTopic
                    topic={pageTeaser.topic}
                    id={id}
                />
                <StyledTitle
                    variant="h3"
                    id={id}>
                    {pageTeaser.title}{" "}
                </StyledTitle>
                <Typography
                    variant="body2"
                    id={id}>
                    {pageTeaser.ingress}
                </Typography>
            </StyledLink>
        </StyledBox>
    )
}

const StyledTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0.5, 0, 0.5, 0),
    "&:hover": {
        textDecoration: "underline"
    }
}))

const StyledLink = styled(Link)(() => ({
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledBox = styled(Box)(({ theme }) => ({
    "&:focus-within": {
        outline: `3px solid ${theme.palette.common.white}`,
        boxShadow: `0px 0px 0px 9px ${theme.palette.secondary.main}`
    }
}))
